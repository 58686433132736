import React from 'react'
import Button from '../../buttons/PrimaryButton/PrimaryButton';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

export default function AboutMeTile() {

    const data = useStaticQuery(graphql`
    query about {
        mdx(slug: {eq: "about"}) {
            frontmatter {
                about {
                  cv
                }
              }
              body
          }
      }
    `);
    const body = data.mdx.body;
    const cv = data.mdx.frontmatter.about.cv;

    return (
        <>
            <MDXRenderer>{body}</MDXRenderer>
            <Button onClick={()=> window.open(cv, '_blank', 'noreferrer')}>
                Download CV
            </Button>
        </>
    )
}
